<template lang="">
<div class="container">
    <div class='over__detail mt-16'>
        <div class='detail__img'>
            <img v-if="dataProduct.Img" :src="`${baseURL}${baseImg}${dataProduct.Img}`" :alt="`${dataProduct.Name}`" class='detail__img--item' />
            <img v-else src='../../../assets/img/logo_remove_bg.png' :alt="`${dataProduct.Name}`" class='detail__img--item' />
        </div>
        <div class='detail__info'>
            <p class='detail__name'>{{dataProduct.Name}}</p>
            <p class='mt-1'>Tình trạng: <span class='detail__des'>{{getStatus(dataProduct.Status)}}</span></p>
            <p class='mt-1'>Số lượng hiện có: <span class='detail__des'>{{dataProduct.Quantity}}</span></p>
            <p class='mt-1 promotionalPrice'>Giá: <span :class='dataProduct.PromotionalPrice !== 0 ? "money" : "promotionalPrice"'>{{ formatMoney(dataProduct.Price)}}</span> {{ formatMoney(dataProduct.PromotionalPrice)}}
                <span class='ml-2 text-base' v-if="dataProduct.PromotionalPrice !== 0">
                    (-{{ ((1-(dataProduct.PromotionalPrice/dataProduct.Price))*100).toFixed(0)}}%)
                </span>
            </p>
            <p v-if="dataProduct.Description" class='mt-1 fw-7'>Thông tin: </p>
            <p class='mt-1'>{{dataProduct.Description}}</p>
            <p class="mt-4 text-lg font-semibold text-[#96ca2e]">Chọn số lượng bên dưới rồi thêm vào giỏ hàng </p>
            <div class="flex gap-4 mt-4">
                <el-input-number v-model="itemCart.Number" :min="1" />
                <button class="button__custom" @click="addItemToCart()">Thêm vào giỏ hàng</button>
            </div>
            <div class="mt-4">
                <button class="button__custom" @click="orderBuyNow()">Mua ngay</button>
            </div>
        </div>
    </div>
    <div class='other__product mb-16'>
        <p class='mt-8 fw-5' style='fontSize: 20px'>Các sản phẩm khác:</p>
        <p v-if='listOrtherProduct.length === 0'>Hiện tại sản phẩm loại này đang không còn. Cửa hàng sẽ cập nhật sản phẩm mới sau.</p>
        <el-row v-else :gutter="10" class='my-4'>
            <el-col v-for="(i) in listOrtherProduct" :key="i.ID" :span="24" :md="6">
                <div class='product__over' @click='clickProduct(i)'>
                    <div class='view__discount' v-if='i.promotionalPrice'>
                        -{{ ((1-(i.PromotionalPrice/i.Price))*100).toFixed(0)}}%
                    </div>
                    <div class='product__view--img'>
                        <img v-if="i.Img" :src="`${baseURL}${baseImg}${i.Img}`" :alt="`${i.Name}`" class='product__img object-cover' />
                        <img v-else src='../../../assets/img/logo_remove_bg.png' :alt="`${i.Name}`" class='product__img object-cover' />
                    </div>
                    {{i.Name}}
                    <div class='product__price--view'>
                        <p class='promotionalPrice'>{{formatMoney(i.PromotionalPrice)}}</p>
                        <p :class='i.PromotionalPrice !== 0 ? "money" : "promotionalPrice"'>{{formatMoney(i.Price)}}</p>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
    <el-dialog v-model="dialogOrder" title="Đơn đặt hàng" class="w-full md:w-[600px]">
        <template #header="{ titleId }">
            <div class="my-header">
                <h4 :id="titleId" class="text-xl font-semibold text-[#96ca2e]">Đơn đặt hàng</h4>
            </div>
        </template>
        <el-table border :data="message" style="width: 100%">
            <el-table-column prop="NameItem" label="Tên sản phẩm" min-width="150px" />
            <el-table-column prop="Image" label="Hình ảnh" min-width="100px">
                <template #default="scope">
                    <img :src="scope.row.Image" alt="Hình ảnh sản phẩm" class="w-16 h-16 object-cover" />
                </template>
            </el-table-column>
            <el-table-column label="Đơn giá" min-width="130px">
                <template #default="scope">
                    <div>{{ formatMoney(scope.row.UnitPrice) }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="Number" label="Số lượng" />
            <el-table-column label="Thành tiền" min-width="130px">
                <template #default="scope">
                    <div class="whitespace-nowrap"> {{ formatMoney(scope.row.Price) }} </div>
                </template>
            </el-table-column>
        </el-table>
        <el-form :model="form" :rules="rules" ref="formRef" label-width="auto" style="max-width: 600px" class="mt-4">
            <el-form-item label="Họ và tên" prop="Username">
                <el-input class="h-9" v-model="form.Username" />
            </el-form-item>
            <el-form-item label="Điện thoại" prop="Phone">
                <el-input class="h-9" v-model="form.Phone" />
            </el-form-item>
            <el-form-item label="Địa chỉ" prop="Address">
                <el-input class="h-9" v-model="form.Address" />
            </el-form-item>
            <el-form-item label="E-mail" prop="Email">
                <el-input class="h-9" v-model="form.Email" />
            </el-form-item>
            <el-form-item label="Ghi chú">
                <el-input class="h-9" v-model="form.Note" />
            </el-form-item>
            <el-form-item>
                <div class="flex w-full justify-end">
                    <el-button class="button__custom button__custom--error h-[44px]" @click="dialogOrder = false">Hủy đơn hàng</el-button>
                    <el-button class="button__custom  md:ml-[50%] h-[44px] " type="success" @click="submitForm">Hoàn tất đặt hàng</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-dialog>
</div>
</template>

<script>
import {
    baseURL,
    baseImg
} from '@/assets/variable';
import formatMoney from '@/mixin.js'
import getStatus from '@/mixin.js'
import {
    addTransaction,
    getListProduct,
    getOrtherProduct
} from '@/api/api'
import {
    ElMessage
} from 'element-plus'

export default {
    name: "DetaiProduct",
    mixins: [formatMoney, getStatus],
    watch: {
        '$route.query.id': {
            immediate: true, // Đảm bảo watch được gọi ngay khi component được tạo
            handler(newId, oldId) {
                if (newId !== oldId) {
                    this.id = newId; // Cập nhật lại giá trị id trong data
                    this.resetView(); // Gọi lại resetView để fetch dữ liệu mới
                }
            }
        }
    },
    data() {
        return {
            id: this.$route.query.id,
            dataProduct: {},
            baseURL,
            baseImg,
            listOrtherProduct: [],
            itemCart: {
                ID: 0,
                NameItem: '',
                Number: 0,
                Price: 0,
                UnitPrice: 0,
                Img: ''
            },
            dialogOrder: false,
            form: {
                Username: "",
                Phone: "",
                Address: "",
                Email: "",
                Note: "",
                Price: 0,
                ShoppingCart: []
            },
            rules: {
                Username: [{
                    required: true,
                    message: "Vui lòng nhập họ và tên",
                    trigger: "blur"
                }, ],
                Phone: [{
                        required: true,
                        message: "Vui lòng nhập số điện thoại",
                        trigger: "blur"
                    },
                    {
                        pattern: /^[0-9]{10,11}$/,
                        message: "Số điện thoại không hợp lệ",
                        trigger: "blur",
                    },
                ],
                Address: [{
                    required: true,
                    message: "Vui lòng nhập địa chỉ",
                    trigger: "blur"
                }, ],
                Email: [{
                    type: "email",
                    message: "Email không hợp lệ",
                    trigger: "blur",
                }, ],
            },
            message: []
        }
    },
    created() {
        this.resetView()
        this.createInfoCustomer()
    },
    methods: {
        createInfoCustomer() {
            let infoCustomer = JSON.parse(localStorage.getItem('infoCustomer'))
            if (infoCustomer) {
                this.form = {
                    ...this.form,
                    Username: infoCustomer.Username,
                    Phone: infoCustomer.Phone,
                    Address: infoCustomer.Address,
                }
            }
        },
        async submitForm() {
            this.$refs.formRef.validate(async (valid) => {
                if (valid) {
                    this.form = {
                        ...this.form,
                        ShoppingCart: this.message,
                        Price: this.message.reduce((total, item) => total + item.Price, 0)
                    };
                    const response = await addTransaction(this.form)
                    if (response.data.success === true) {
                        ElMessage({
                            message: 'Đã tạo đơn hàng thành công, bạn chú ý điện thoại để nhân viên sẽ liên hệ.',
                            type: 'success',
                        })
                        localStorage.setItem("infoCustomer", JSON.stringify(this.form));
                        localStorage.removeItem('shoppingCart');
                        location.reload();
                        this.$router.push({
                            path: '/'
                        });
                    } else {
                        ElMessage({
                            message: 'Tạo đơn thất bại, hãy kiểm tra lại hoặc liên hệ với admin',
                            type: 'error',
                        })
                    }
                } else {
                    console.error("Form validation failed");
                }
            });
        },
        back() {
            this.$router.go(-1)
        },
        resetView() {
            try {
                this.setData()
                this.getDataOrther()
            } catch (error) {
                console.error(error);
            }
        },
        async setData() {
            const response = await getListProduct(this.id)
            this.dataProduct = response.data
        },
        async getDataOrther() {
            const response = await getOrtherProduct(this.id)
            this.listOrtherProduct = response.data
        },
        clickProduct(e) {
            this.$router.push({
                path: '/product',
                query: {
                    id: e.ID
                }
            });
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        orderBuyNow() {
            this.dialogOrder = true
            let PriceMessage = this.dataProduct.PromotionalPrice !== 0 ? this.dataProduct.PromotionalPrice : this.dataProduct.Price
            this.message = [{
                ID: this.dataProduct.ID,
                Img: this.dataProduct.Img,
                NameItem: this.dataProduct.Name,
                Number: this.itemCart.Number,
                UnitPrice: PriceMessage,
                Price: this.itemCart.Number * PriceMessage
            }]
            console.log('this.dataProduct', this.dataProduct);
        },
        addItemToCart() {
            if (typeof this.dataProduct === 'object' && this.dataProduct !== null && !Array.isArray(this.dataProduct)) {
                let PriceMessage = this.dataProduct.PromotionalPrice !== 0 ? this.dataProduct.PromotionalPrice : this.dataProduct.Price
                this.itemCart = {
                    ...this.itemCart,
                    ID: this.dataProduct.ID,
                    Img: this.dataProduct.Img,
                    NameItem: this.dataProduct.Name,
                    UnitPrice: PriceMessage,
                    Price: PriceMessage * this.itemCart.Number
                }
                let shoppingCart = JSON.parse(localStorage.getItem('shoppingCart')) || [];
                let findItem = shoppingCart.find(item => item.ID === this.itemCart.ID)
                if (findItem) {
                    var itemCartTemp = {
                        ...this.itemCart,
                        Number: this.itemCart.Number + findItem.Number,
                        Price: PriceMessage * (this.itemCart.Number + findItem.Number)
                    }
                    shoppingCart = shoppingCart.map(item =>
                        item.ID === itemCartTemp.ID ? itemCartTemp : item
                    );
                } else {
                    shoppingCart.push(this.itemCart)
                }
                localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart));
                ElMessage({
                    message: 'Đã thêm thành công sản phẩm vào giỏ hàng',
                    type: 'success',
                })
                location.reload();
            } else {
                ElMessage({
                    message: 'Hiện đang không có sản phẩm nào, thử lại sau',
                    type: 'error',
                })
            }

        }
    }
}
</script>
